<template>
  <div class="settings-setting">
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Setting',
  components: {}
})
export default class SettingsSetting extends Vue {
}
</script>
<style lang="scss" scoped>
.settings-setting {
  max-width: 500px;
}
</style>
