
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Card from '@/02-molecules/Card.vue'
import Button from '@/01-atoms/Button.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Setting--language-preferences',
  components: {
    IconButton,
    Card,
    Button,
    InputRadio,
    InputLabel,
    BackButton
  }
})
export default class SettingsPreferredLanguage extends Vue {
}
