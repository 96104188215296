<template>
  <BackButton :to="{name: 'Settings'}" />
  <h1 class="display-1 mb-l">{{ $t('settings.notifications.title')}}</h1>
  <Card class="mb-l">
    <template #title>{{ $t('settings.notifications.support.title')}}</template>
    <p class="grey-dark">{{ $t('settings.notifications.support.label')}}</p>
    <Switch id="system-notifications-messages" locked checked>{{ $t('settings.notifications.system.label')}}</Switch>
    <Switch id="email-messages">{{ $t('settings.notifications.email.label')}}</Switch>
  </Card>
  <Card class="mb-l">
    <template #title>{{ $t('settings.notifications.status.title')}}</template>
    <p class="grey-dark">{{ $t('settings.notifications.status.title')}}</p>
    <Switch id="system-notifications-status" locked checked>{{ $t('settings.notifications.system.label')}}</Switch>
    <Switch id="email-status">{{ $t('settings.notifications.email.label')}}</Switch>
  </Card>
  <Card class="mb-l">
    <template #title>{{ $t('settings.notifications.certificates.title')}}</template>
    <p class="grey-dark">{{ $t('settings.notifications.certificates.title')}}</p>
    <Switch id="system-notifications-certificates" locked checked>{{ $t('settings.notifications.system.label')}}</Switch>
    <Switch id="email-certificates">{{ $t('settings.notifications.email.label')}}</Switch>
  </Card>
  <Card class="mb-l">
    <template #title>{{ $t('settings.notifications.payments.title')}}</template>
    <p class="grey-dark">{{ $t('settings.notifications.payments.label')}}</p>
    <Switch id="system-notifications-payment" locked checked>{{ $t('settings.notifications.system.label')}}</Switch>
    <Switch id="email-payment">{{ $t('settings.notifications.email.label')}}</Switch>
  </Card>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Card from '@/02-molecules/Card.vue'
import Switch from '@/01-atoms/Switch.vue'
import Button from '@/01-atoms/Button.vue'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Setting--notification-settings',
  components: {
    Card,
    Switch,
    Button,
    BackButton
  }
})
export default class SettingsNotificationSettings extends Vue { }
</script>
