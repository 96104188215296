
import { Options, Vue } from 'vue-class-component'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Settings-text',
  components: {
    BackButton
  }
})
export default class SettingsText extends Vue { }
