<template>
  <BackButton :to="{name: 'Settings'}" />
  <h1 class="display-1 mb-l">{{ $t('settings.login-security.title')}}</h1>
  <Card class="mb-l">
    <template #title>{{ $t('settings.login-security.change-password.title')}}</template>
    <InputLabel class="tt-uppercase">{{ $t('settings.login-security.current-password.label')}}</InputLabel>
    <InputText class="mb-m" type="password"></InputText>
    <router-link to="/" class="grey-dark fw-700 fs-12 tt-uppercase d-b mb-xl">{{ $t('settings.login-security.forgot-password.label')}}</router-link>
    <InputLabel class="tt-uppercase">{{ $t('settings.login-security.new-password.label')}}</InputLabel>
    <InputText class="mb-m" type="password"></InputText>
    <InputLabel class="tt-uppercase">{{ $t('settings.login-security.repeat-new-password.label')}}</InputLabel>
    <InputText class="mb-l" type="password"></InputText>
    <div class="ta-center">
      <Button>{{ $t('global.save')}}</Button>
    </div>
  </Card>
  <Card>
    <template #title>{{ $t('settings.login-security.change-email.title')}}</template>
    <InputLabel class="tt-uppercase">{{ $t('settings.login-security.current-email.label')}}</InputLabel>
    <InputText class="mb-xl" type="email"></InputText>
    <InputLabel class="tt-uppercase">{{ $t('settings.login-security.new-email.label')}}</InputLabel>
    <InputText class="mb-m" type="email"></InputText>
    <InputLabel class="tt-uppercase">{{ $t('settings.login-security.repeat-new-email.label')}}</InputLabel>
    <InputText class="mb-l" type="email"></InputText>
    <div class="ta-center">
      <Button>{{ $t('global.save')}}</Button>
    </div>
  </Card>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Card from '@/02-molecules/Card.vue'
import Button from '@/01-atoms/Button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Setting--login-security',
  components: {
    Card,
    Button,
    InputText,
    InputLabel,
    BackButton
  }
})
export default class SettingsLoginSecurity extends Vue { }
</script>
