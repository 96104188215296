<template>
  <BackButton :to="{name: 'Settings'}" />
  <div class="settings-text format-text">
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Settings-text',
  components: {
    BackButton
  }
})
export default class SettingsText extends Vue { }
</script>
<style lang="scss">
.settings-text {
  max-width: 850px;
}
</style>
