
import { Options, Vue } from 'vue-class-component'
import Card from '@/02-molecules/Card.vue'
import Button from '@/01-atoms/Button.vue'
import InputText from '@/01-atoms/Input-text.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Setting--login-security',
  components: {
    Card,
    Button,
    InputText,
    InputLabel,
    BackButton
  }
})
export default class SettingsLoginSecurity extends Vue { }
