<template>
  <h1 class="display-1">{{ $t('settings.privacy-policy.title')}}</h1>
  <h2>1. My CMCM account</h2>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vulputate commodo tortor, in vestibulum libero porta vitae. Proin nunc est, cursus eget gravida ac, dapibus at eros. Suspendisse suscipit elit vitae euismod lacinia. Maecenas feugiat velit id tortor imperdiet, a commodo eros consectetur. Integer quis dictum est. Cras libero sapien, gravida vel venenatis sit amet, rhoncus hendrerit risus. Sed at aliquet lectus, porta sollicitudin lectus. Curabitur iaculis, mauris ac aliquet commodo, felis quam efficitur mi, sit amet gravida eros ipsum nec libero. Quisque tortor magna, lacinia at tristique ultricies, auctor sit amet sem. Aenean malesuada pharetra ligula eu tempor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur dui lorem, efficitur in purus sed, pellentesque pretium mauris. Nulla id nisi luctus lectus ultrices lacinia ac a lacus. Donec purus dui, efficitur ac nibh nec, venenatis ornare sem. Curabitur nisl nibh, sagittis vel iaculis vitae, rutrum eget risus. Aliquam nec condimentum metus. Aenean posuere diam vel finibus scelerisque.</p>
  <h2>2. My CMCM account</h2>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vulputate commodo tortor, in vestibulum libero porta vitae. Proin nunc est, cursus eget gravida ac, dapibus at eros. Suspendisse suscipit elit vitae euismod lacinia. Maecenas feugiat velit id tortor imperdiet, a commodo eros consectetur. Integer quis dictum est. Cras libero sapien, gravida vel venenatis sit amet, rhoncus hendrerit risus. Sed at aliquet lectus, porta sollicitudin lectus. Curabitur iaculis, mauris ac aliquet commodo, felis quam efficitur mi, sit amet gravida eros ipsum nec libero. Quisque tortor magna, lacinia at tristique ultricies, auctor sit amet sem. Aenean malesuada pharetra ligula eu tempor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur dui lorem, efficitur in purus sed, pellentesque pretium mauris. Nulla id nisi luctus lectus ultrices lacinia ac a lacus. Donec purus dui, efficitur ac nibh nec, venenatis ornare sem. Curabitur nisl nibh, sagittis vel iaculis vitae, rutrum eget risus. Aliquam nec condimentum metus. Aenean posuere diam vel finibus scelerisque.</p>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Settings-text--privacy-policy'
})

export default class SettingsTextPrivacyPolicy extends Vue { }
</script>
