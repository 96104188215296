<template>
  <BackButton :to="{name: 'Settings'}" />
  <h1 class="display-1 mb-l">{{ $t('settings.levels.title')}}</h1>
  <Card class="mb-l">
    <template #title>{{ $t('settings.levels.two-factor.title')}}</template>
    <p class="grey-dark">{{ $t('settings.levels.two-factor.label')}}</p>
    <Switch class="mb-l" checked locked>{{ $t('settings.levels.two-factor.phone-verified.label')}}</Switch>
    <InputLabel>{{ $t('global.phone-number')}}</InputLabel>
    <InputText value="+352 691 000 000" class="mb-l"></InputText>
    <div class="ta-center">
      <Button>{{ $t('global.save')}}</Button>
    </div>
  </Card>
  <Card class="mb-l">
    <template #title>{{ $t('settings.levels.identity.title')}}</template>
    <p class="grey-dark">{{ $t('settings.levels.identity.label')}}</p>
    <Switch checked locked>{{ $t('settings.levels.identity-verified.label')}}</Switch>
  </Card>
  <Card class="mb-l">
    <template #title>{{ $t('settings.levels.app.title')}}</template>
    <p class="grey-dark">{{ $t('settings.levels.app.label')}}</p>
    <Switch checked locked>{{ $t('settings.levels.app-verified.label')}}</Switch>
  </Card>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Card from '@/02-molecules/Card.vue'
import Switch from '@/01-atoms/Switch.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import Button from '@/01-atoms/Button.vue'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Setting--security-levels',
  components: {
    Card,
    Switch,
    InputLabel,
    InputText,
    Button,
    BackButton
  }
})
export default class SettingsSecurityLevels extends Vue { }
</script>
