
import { Options, Vue } from 'vue-class-component'
import Card from '@/02-molecules/Card.vue'
import Switch from '@/01-atoms/Switch.vue'
import Button from '@/01-atoms/Button.vue'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Setting--notification-settings',
  components: {
    Card,
    Switch,
    Button,
    BackButton
  }
})
export default class SettingsNotificationSettings extends Vue { }
