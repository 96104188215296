<template>
  <BackButton :to="{name: 'Settings'}" />
  <h1 class="display-1 mb-l">{{ $t('settings.language.title')}}</h1>
  <Card class="mb-l">
    <template #title>{{ $t('settings.preferred-language.title')}}</template>
    <InputLabel class="tt-uppercase mb-s">{{ $t('settings.preferred-language.label')}}</InputLabel>
    <InputRadio group="language" inputId="english" class="mr-l mb-s" checked>English</InputRadio>
    <InputRadio group="language" inputId="german" class="mr-l mb-s">German</InputRadio>
    <InputRadio group="language" inputId="french" class="mr-l mb-s">French</InputRadio>
    <InputRadio group="language" inputId="luxemburgish" class="mr-l mb-l">Luxemburgish</InputRadio>
    <div class="ta-center">
      <Button>{{ $t('global.save')}}</Button>
    </div>
  </Card>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Card from '@/02-molecules/Card.vue'
import Button from '@/01-atoms/Button.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import BackButton from '@/02-molecules/Back-button.vue'

@Options({
  name: 'Setting--language-preferences',
  components: {
    IconButton,
    Card,
    Button,
    InputRadio,
    InputLabel,
    BackButton
  }
})
export default class SettingsPreferredLanguage extends Vue {
}
</script>
<style lang="scss" scoped>

</style>
