
import { Options, Vue } from 'vue-class-component'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'
import SettingCard from '@/03-organisms/Setting-card.vue'

@Options({
  name: 'Settings',
  components: {
    TitleDecoration,
    SettingCard
  }
})
export default class Settings extends Vue {
  yourAccountCards = [
    {
      icon: 'lock',
      title: 'Security levels',
      content: 'If you need help, write us and we will respond to you as soon as possible',
      totalSteps: '3',
      step: '2'
    },
    {
      icon: 'log-in',
      title: 'Login and security',
      content: 'Change your password or email address'
    },
    {
      icon: 'message-square',
      title: 'Language preferences',
      content: 'Change your language of communicatiion with us.'
    },
    {
      icon: 'info',
      title: 'Notification settings',
      content: 'Manage your CMCM notifications. Choose if you prefer to receive e-mail or system alerts.'
    }
  ]

  aboutsCards = [
    {
      icon: 'book',
      title: 'Terms and conditions',
      content: 'If you need help, write us and we will respond to you as soon as possible'
    },
    {
      icon: 'file-text',
      title: 'Privacy policy',
      content: 'Change your password or email address'
    }
  ]
}
